import React, { useState, useEffect } from 'react';
import GenericModal from '../Base/GenericModal';
import axios from 'axios';

const ImportEmployeesModal = ({ modalIsOpen, closeModal, companyId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    console.log('companyId:', companyId);
  }, [companyId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      setStatusMessage('Choisissez un fichier à importer');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/employees/upload/${companyId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setLoading(false);
      setStatusMessage('Fichier importé avec succès');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setLoading(false);
      // console.error('Error uploading file:', error);
      setStatusMessage('Une erreur est survenue lors de l\'importation du fichier. Veuillez réessayer.');
    }
  };

  return (
    <GenericModal isOpen={modalIsOpen} closeModal={closeModal}>
      <h2 className='text-xl font-bold text-slate-900 mb-1'>Importer des employés</h2>
      <h2 className='text-md font-regular text-slate-600'>Ajoutez un nouvel employé à votre compte.</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="file"
          onChange={(e) => setSelectedFile(e.target.files[0])}
          className='file-input file-input-bordered w-full max-w-xs my-6'
        />
        <div className='px-6 mb-6 flex w-full absolute bottom-0 left-0 right-0'>
          <button type="submit" className='btn btn-primary bg-bsrate text-white border-none w-full ml-0 bottom-0 left-0 right-0'>Importer</button>
        </div>
      </form>

      {loading && <p className='text-left text-sm mb-3 -mt-3 text-blue-600 mb-4'>Import en cours...</p>}
      {statusMessage && <p className={`text-left text-sm mb-3 -mt-3 ${statusMessage.includes('Error') ? 'text-red-600' : 'text-green-600'}`}>{statusMessage}</p>}

      <h5 className='text-sm font-semibold text-slate-900 mb-1'>Comment faire?</h5>
      <p className='text-sm text-slate-700'>
        1. Télécharger le fichier Excel source <a href='/files/BackstageRate_Import_Employes.xlsx' className='underline text-bsrate'> en cliquant ici</a> <br/>
        2. Remplissez les colonnes présentes (seuls le prénom, le nom et l'email sont obligatoires)<br/>
        3. Exporter votre fichier en csv pour l'importer ici
      </p>
    </GenericModal>
  );
};

export default ImportEmployeesModal;
