// src/pages/SamplePage.jsx
import React from 'react';
import DepartmentDistributionChart from '../Stats/DepartmentDistributionChart';
import GenderDistributionChart from '../Stats/GenderDistributionChart'; 
import TotalEmployeesStat from '../Stats/TotalEmployeesStat';
import TotalSentSurveysStat from '../Stats/TotalSentSurveysStat';
import SentSurveysChart from '../Stats/SentSurveysChart';
import AllSurveysAverageRating from './AllSurveysAverageRating';
import ContractTypeDistributionChart from '../Stats/ContractTypeDistributionChart';

const StatsCompany = () => {
    return (
        <div className='flex flex-col bg-white border border-slate-100 rounded-xl px-8 py-6 pb-8'>
            <div className='font-bold text-bsrate text-xl pb-6'>Votre entreprise</div>
            <div className='flex flex-col md:flex-row gap-x-8 mb-8'>
                {/* <div className='w-1/3 flex flex-col gap-y-4'>
                     <AllSurveysAverageRating />
                    <TotalEmployeesStat />
                    <TotalSentSurveysStat />

                </div> */}
                <div className='md:w-1/3'>
                    <DepartmentDistributionChart />
                </div>
                <div className='md:w-1/3'>
                    <GenderDistributionChart />
                </div>
                 <div className='md:w-1/3'>
                    <ContractTypeDistributionChart />
                    
                </div>
            </div>
            <div className='flex flex-row gap-x-8'>
                <div className='w-1/3'>
                </div>
                <div className='w-2/3'>
                    {/* <SentSurveysChart /> */}
                </div>
            </div>
        </div>
    );
};

export default StatsCompany;
