import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FileBase from 'react-file-base64';

function InfosSection() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        siret: "",
        address: "",
        city: "",
        postalCode: "",
        numOfEmployees: "", // Add this if necessary
        image: ""
    });
    const [fileName, setFileName] = useState("");
    const [isLoading, setIsLoading] = useState(false); // State for managing the loading overlay

    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const fetchCompanyData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/v2/companies/${companyId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setFormData(response.data.company);
                setFileName(localStorage.getItem("companyProfile") || "current-file.png");
            } catch (error) {
                console.error("Failed to fetch company data:", error);
                toast.error("Failed to fetch company data.");
            }
        };
        fetchCompanyData();
    }, [companyId, token]);

    const updateCompanyDetails = async () => {
        setIsLoading(true); // Show the loading overlay
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_BACKEND_URL}/api/v2/companies/update/${companyId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                // toast.success("Company details updated successfully");
                setTimeout(() => {
                    setIsLoading(false); // Hide the loading overlay
                    window.location.reload();
                }, 1000); // Display the overlay for 2 seconds
            }
        } catch (error) {
            console.error("Failed to update company details:", error);
            toast.error("Failed to update company details.");
            setIsLoading(false); // Hide the loading overlay in case of error
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, image: reader.result });
            };
            reader.readAsDataURL(file);
            setFileName(file.name);
            localStorage.setItem("companyProfile", file.name);
        }
    };

    return (
        <div className="relative">
            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    
<button disabled type="button" class="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
<svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
</svg>
Changement en cours...
</button>

                </div>
            )}
            <div className="p-6 px-8">
                <h3 className="text-lg font-semibold text-slate-900">Mon entreprise</h3>
                <p className="text-slate-500">Les informations de votre entreprise</p>
                <div className="w-3/6">
                    <div className="col-span-full flex items-center gap-x-8 mt-6">
                        <div className="mt-0 mb-2">
                            <label className="block text-sm font-medium leading-6 text-gray-900 mb-2">
                                Logo de l'entreprise
                            </label>
                            <div>
                                <input
                                    type="file"
                                    id="file-upload"
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                                <label htmlFor="file-upload" className="btn btn-primary bg-bsrate text-white">
                                Choisir une image
                            </label>
                            <span className="ml-4 text-sm">(png, jpg ou jpeg - 5Mo max.)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 mb-1.5">
                        Nom de l'entreprise
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="input w-full"
                        placeholder="Your Company Name"
                    />
                </div>
                <div className="mt-3">
                    <label htmlFor="siret" className="block text-sm font-medium leading-6 text-gray-900 mb-1.5">
                        SIRET
                    </label>
                    <input
                        type="text"
                        id="siret"
                        name="siret"
                        value={formData.siret}
                        onChange={handleChange}
                        className="input w-full"
                        placeholder="Your SIRET number"
                        disabled
                    />
                </div>
                <div className="mt-3">
                    <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                        Addresse
                    </label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        className="input w-full"
                        placeholder="Your Address"
                    />
                </div>
                <div className="mt-3">
                    <label htmlFor="postalCode" className="block text-sm font-medium leading-6 text-gray-900 mb-1.5">
                        Code postal
                    </label>
                    <input
                        type="text"
                        id="postalCode"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleChange}
                        className="input w-full"
                        placeholder="Your Postal Code"
                    />
                </div>
                <div className="mt-3">
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                        Ville
                    </label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        className="input w-full"
                        placeholder="Your City"
                    />
                </div>
                <button
                    type="button"
                    className="btn bg-bsrate hover:bg-black text-white mt-4 mb-1"
                    onClick={updateCompanyDetails}
                >
                    Mettre à jour
                </button>
            </div>
        </div>
    );
}

export default InfosSection;

