import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (token && user) {
            setAuthData({ token, user });
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        setLoading(false);
    }, []);

    const signIn = async (email, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/auth/login`, { email, password });
            if (response.status === 200) {
                const { token, user } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('companyId', user.companyId);
                localStorage.setItem('companyName', user.companyName);
                setAuthData({ token, user });
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        } catch (error) {
            console.error("Login error:", error);
            throw new Error("Login failed");
        }
    };

    const signOut = () => {
        localStorage.clear();
        setAuthData(null);
        window.location.href = '/signin'; // Redirect to sign-in page after logging out
    };

    if (loading) {
        return <div></div>; // Loading indicator
    }

    return (
        <AuthContext.Provider value={{ authData, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
