import React, { useEffect, useState } from 'react';
import { getAverageRatingByCategory } from '../../services/surveysService';
import { toast } from 'react-toastify';
import StarsBase from './StarsBase'; // Import the custom Star component
import Star2 from './StarsBase2';

const CategoryAverageRating = ({ category }) => {
  const [averageRating, setAverageRating] = useState(0);
  const adminId = localStorage.getItem("userId"); // Retrieve userId from local storage

  useEffect(() => {
    const fetchAverageRating = async () => {
      if (!adminId) {
        console.error('Admin ID is missing');
        toast.error("Admin ID is missing");
        return;
      }

      try {
        console.log(`Fetching average rating for category: ${category}, adminId: ${adminId}`);
        const data = await getAverageRatingByCategory(category, adminId); // Pass adminId to the service
        console.log(`Received average rating data:`, data);
        setAverageRating(data.averageRating);
      } catch (error) {
        console.error('Failed to fetch average rating:', error);
        toast.error("Failed to fetch average rating");
      }
    };

    fetchAverageRating();
  }, [category, adminId]);

  const renderStars = (rating) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const partialStar = rating - fullStars;
    const stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < fullStars) {
        stars.push(<Star2 key={i} fill={100} />); // Full star
      } else if (i === fullStars) {
        stars.push(<Star2 key={i} fill={partialStar * 100} />); // Partial star
      } else {
        stars.push(<Star2 key={i} fill={0} />); // Empty star
      }
    }

    return <div className="flex">{stars}</div>;
  };

  return (
    <div className="flex flex-col w-full p-4 bg-bsrate border border-bsrate shadow rounded-lg">
      <h3 className="text-sm font-semibold text-white mb-2">Note {category}</h3>
      <div className="flex items-center">
        {renderStars(averageRating)}
        <span className="ml-2 text-xl font-bold text-white">{averageRating}</span>
      </div>
    </div>
  );
};

export default CategoryAverageRating;
