import React, { useEffect, useState } from 'react';
import { getSurveyCountByCategoryForCurrentMonth } from '../../services/surveysService';
import { toast } from 'react-toastify';

const CategorySurveyCountCurrentMonth = ({ category }) => {
  const [surveyCount, setSurveyCount] = useState(0);
  const adminId = localStorage.getItem("userId"); // Use the same key as the working component

  // Get the current month and year
  const currentDate = new Date();
  const currentMonthYear = currentDate.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });

  useEffect(() => {
    const fetchSurveyCount = async () => {
      try {
        const data = await getSurveyCountByCategoryForCurrentMonth(adminId, category);
        setSurveyCount(data.surveyCount);
      } catch (error) {
        console.error('Failed to fetch survey count for current month:', error);
        toast.error("Failed to fetch survey count for current month");
      }
    };

    fetchSurveyCount();
  }, [category, adminId]);

  return (
    <div className="flex flex-col w-full p-4 bg-card border border-bsrate shadow rounded-lg">
      <div className="flex flex-row items-center justify-start mb-4">
        <h3 className="text-sm font-semibold text-bsrate mb-2">Enquêtes réalisées en {currentMonthYear}</h3>
      </div>
      <div className="flex items-center">
        <span className="ml-2 text-xl font-bold text-bsrate">{surveyCount}</span>
      </div>
    </div>
  );
};

export default CategorySurveyCountCurrentMonth;
