import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const OnboardingSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const markAsPaid = async () => {
            const token = localStorage.getItem('token');
            const sessionId = new URLSearchParams(window.location.search).get('session_id');

            if (sessionId) {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/api/v2/webhook/payment-success`,
                        { sessionId },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    if (response.status === 200) {
                        navigate('/'); // Redirect to the dashboard
                    } else {
                        console.error('Failed to mark as paid');
                    }
                } catch (error) {
                    console.error('Error marking as paid:', error);
                }
            }
        };

        markAsPaid();
    }, [navigate]);

    return <div>Processing your payment...</div>;
};

export default OnboardingSuccess;
