import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';

const DEFAULT_COLORS = ['#675DF5', '#F13C93', '#7F23AB']; // Additional colors for other genders if needed
const GREY_COLOR = '#D3D3D3';
const INDIGO_COLOR = '#4e34d1'; // Color for 'Homme'
const FUCHSIA_COLOR = '#d134cc'; // Color for 'Femme'

const GenderDistributionChart = () => {
    const [genderData, setGenderData] = useState([]);
    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/employees/company/${companyId}/employees`);
                const employees = response.data.employees.filter(employee => !employee.isDeleted);
                const genderCounts = employees.reduce((acc, curr) => {
                    const gender = curr.gender || 'Pas de données';
                    acc[gender] = (acc[gender] || 0) + 1;
                    return acc;
                }, {});

                const formattedData = [
                    ['Gender', 'Count'],
                    ...Object.entries(genderCounts).map(([key, value]) => [key, value])
                ];
                
                setGenderData(formattedData);
            } catch (error) {
                console.error('Failed to fetch employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    const getColorArray = (data) => {
        const colorMap = new Map();
        let colorIndex = 0;

        data.slice(1).forEach(item => {
            const gender = item[0];
            if (gender === 'Pas de données') {
                colorMap.set(gender, GREY_COLOR);
            } else if (gender === 'Homme') {
                colorMap.set(gender, INDIGO_COLOR);
            } else if (gender === 'Femme') {
                colorMap.set(gender, FUCHSIA_COLOR);
            } else if (!colorMap.has(gender)) {
                colorMap.set(gender, DEFAULT_COLORS[colorIndex % DEFAULT_COLORS.length]);
                colorIndex++;
            }
        });

        return data.slice(1).map(item => colorMap.get(item[0]));
    };

    const colors = getColorArray(genderData);

    return (
        <div className="chart-container" style={{ width: '22vw', margin: '0 auto', padding: '20px' }}>
            <h2 className="text-center mb-0 font-semibold mb-1">Parité</h2>
            <p className='text-center text-sm text-slate-700'>Comment votre entreprise se situe-t-elle ?</p>
            <Chart
                chartType="PieChart"
                data={genderData}
                options={{
                    pieHole: 0.4,
                    legend: {
                        position: 'bottom', // Position the legend at the bottom
                        alignment: 'center', // Align legend to the center
                        maxLines: 2 // Allow legend to wrap into multiple lines
                    },
                    chartArea: { width: '90%', height: '80%' }, // Adjust chart area to add space for legend
                    colors: colors
                }}
                width="100%"
                height="300px"
            />
        </div>
    );
};

export default GenderDistributionChart;
