import React from 'react';

const StarRating2 = ({ averageRating }) => {
  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(averageRating); // Number of full stars
    const halfStar = averageRating % 1 !== 0; // Check if there's a half star

    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<span key={i} className="star2 filled">★</span>);
      } else if (i === fullStars + 1 && halfStar) {
        stars.push(<span key={i} className="star2 half-filled">★</span>);
      } else {
        stars.push(<span key={i} className="star2 empty">★</span>);
      }
    }
    return stars;
  };

  return <div className="rating flex">{renderStars()}</div>;
};

export default StarRating2;
