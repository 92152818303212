import React from 'react';
import { HomeIcon, CheckIcon, DocumentPlusIcon, UserPlusIcon, ChartPieIcon, BuildingOfficeIcon, DocumentDuplicateIcon, LifebuoyIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'

const HomeCard = () => {
    const introduction = {
        title: "Bienvenue sur BackstageRate",
        description: "Augmentez la satisfaction de vos employés et réduisez votre taux de rotation du personnel grâce à BackStageRate.",
        list: [
            "Lancez des enquêtes auprès de vos collaborateurs",
            "Collectez des retours précieux",
            "Améliorez la satisfaction de vos employés"
        ]
    };

    const navigationItems = [

        {
            label: "Ajouter des employés",
            href: "/employees",
            icon: UserPlusIcon // Example icon for "Avatar"
        },

        {
            label: "Lancez une enquête",
            href: "/new-survey",
            icon: BuildingOfficeIcon // Example icon for "Button"
        },
                {
            label: "Voir vos données",
            href: "/synthesis",
            icon: DocumentPlusIcon // Example icon for "Alert"
        },
        {
            label: "Tous les résultats",
            href: "/surveys",
            icon: ChartPieIcon // Example icon for "Badge"
        },
        
    ];

    return (
        <div className="flex flex-col md:flex-row  w-full items-stretch  rounded-lg overflow-hidden  gap-4">
            <div className=" md:w-2/3 bg-white  flex flex-row border rounded-lg md:max-h-64 border-bsrate last:border-0 ">
                <img src='images/homecards/8.jpg' alt='Onboarding Image' className='w-1/3 h-auto object-cover' />
                <div className="flex flex-col md:flex-col p-6 py-5 ">
                    <h3 className="font-bold text-xl mb-2 text-bsrate">{introduction.title}</h3>
                    <p className="text-md text-slate-900 dark:text-gray-400">
                        {introduction.description}
                    </p>
                    <ul className="list-none pl-0 mt-4">
                        {introduction.list.map((item, index) => (
                            <li key={index} className="flex mb-2 items-center font-medium text-slate-900">
                                <CheckIcon className="w-5 h-5 mr-3 border border-green-500 p-0.5 rounded-full bg-green-500 text-white" />
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
           
            <div className=" md:w-1/3 bg-white border border-bsrate rounded-lg">
                <div className="flex flex-col p-6 gap-4 pt-5">
                    <h3 className="font-bold text-lg text-bsrate">Comment commencer ?</h3>
                    <p className="text-md leading-none text-slate-900 dark:text-gray-400 -mt-2">
                        Lancez vos enquêtes en quelques minutes.
                    </p>
                    <nav className="flex flex-col gap-3 text-md pt-1.5 ">
                        {navigationItems.map((item, index) => (
                            <a key={index}
                                className="flex items-center gap-5 text-slate-800 hover:text-indigo-600 font-medium rounded-md transition-colors hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-50"
                                href={item.href}>
                                <item.icon className="w-4 h-4 text-bsrate" />
                                {item.label}
                            </a>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default HomeCard;
