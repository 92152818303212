import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

const Star2 = ({ fill }) => {
  return (
    <div className="relative h-6 w-6">
      <StarIcon className="absolute inset-0 text-gray-300" />
      <StarIcon
        className="absolute inset-0 text-yellow-400" // Use text-bsrate class for filled part
        style={{ clipPath: `inset(0 ${100 - fill}% 0 0)` }}
      />
    </div>
  );
};

export default Star2;
