import React, { Fragment, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { SurveyContext } from "../../surveyContext";
import AddEmpForm from "./addEmpForm";

const AddSurveyNickName = ({ currentForm, setCurrentForm, token }) => {
  const [nickName, setNickName] = useState("");
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState(false);
  const { survey } = useContext(SurveyContext);
  const navigate = useNavigate();

  const adminId = localStorage.getItem("userId");
  const employeeEmails = JSON.parse(localStorage.getItem("selectedEmployee")); // Parse JSON
  const totalEmployees = localStorage.getItem("totalEmployees");
  const surveyId = localStorage.getItem("surveyId");

  useEffect(() => {
    // Log token for debugging
    console.log("Survey token from prop:", token);
    if (!token) {
      console.error("Token is null or undefined.");
    }
  }, [token]);

  const handleSendMails = async () => {
    if (!token) {
      console.error("Token is missing. Cannot send request.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/surveyinvitations/${adminId}/${token}`,
        { employeeEmails, nickName, surveyId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        localStorage.setItem("totalMails", response.data.totalMails);
        localStorage.removeItem("selectedEmployee");
        localStorage.removeItem("totalEmployees");
        localStorage.removeItem("surveyId");
        // toast.success("Email sent to Employees");
        navigate("/send-survey-mails");
      }
    } catch (error) {
      console.error("Error while sending backend request:", error.message);
    }
  };

  const handleBack = () => {
    setCurrentForm("empForm");
    setIsSurveyModalOpen(true);
  };

  return (
    <Fragment>
      {currentForm === "surveyNickName" ? (
        <div className="box">
          <div className="box px-6 py-8 pb-4">
            <p className="uppercase text-sm text-slate-400 font-semibold">Votre enquête</p>
            <p className="text-xl text-slate-900 font-bold">{survey.name}</p>
            <div className="Nname">
              <h4 className="font-medium text-sm pb-1 pt-4">Donnez un nom à cet envoi</h4>
              <input
                className="input input-bordered w-full max-w-xs"
                placeholder="Nom personnalisé"
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
              />
              <h4 className="font-normal text-md text-slate-600 pt-8">
                Cette enquête sera envoyée à {totalEmployees} employé(s).
              </h4>
            </div>
          </div>

          <div className="flex gap-4 px-6 grid-col-2 ">
            <button className="btn w-48" onClick={handleBack}>
              Retour
            </button>
            <button className="btn btn-primary bg-bsrate  text-white w-48" onClick={handleSendMails}>
              Envoyer →
            </button>
          </div>
        </div>
      ) : (
        <AddEmpForm isSurveyModalOpen={isSurveyModalOpen} setIsSurveyModalOpen={setIsSurveyModalOpen} />
      )}
    </Fragment>
  );
};

export default AddSurveyNickName;
