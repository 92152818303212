import React from 'react';
import SignUp from '../components/Auth/SignUp';

const SignUpPage = () => {
  return (
    <div class='bg-bsrate2'>
      <SignUp />
      <p>
      </p>
    </div>
  );
};

export default SignUpPage;
