import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import { DocumentCheckIcon, PlusIcon } from '@heroicons/react/24/solid';
import { getCategoriesFromSurveys } from "../../services/surveysService";

const CreateSurveyForm = ({ onSurveyCreated }) => {
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyCategory, setSurveyCategory] = useState("");
  const [surveyDescription, setSurveyDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [image, setImage] = useState(null);
  const [categories, setCategories] = useState([]); // State to store categories
  const [isLoading, setIsLoading] = useState(false); // State for managing the loading overlay

  const adminId = localStorage.getItem("userId");

  // Fetch categories from the backend
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getCategoriesFromSurveys();
        setCategories(categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Failed to fetch categories");
      }
    };

    fetchCategories();
  }, []);

  const addQuestion = () => {
    const newQuestion = {
      Question_order: questions.length + 1,
      Question_category: "",
      Type: "",
      Lower_label: "",
      Upper_label: "",
    };
    setQuestions([...questions, newQuestion]);
  };

  const handleSurveyImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = { ...newQuestions[index], [field]: value };
    setQuestions(newQuestions);
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true); // Show the loading overlay

  const formData = new FormData();
  formData.append('Survey_title', surveyTitle);
  formData.append('Survey_category', surveyCategory);
  formData.append('Survey_description', surveyDescription);
  formData.append('Questions', JSON.stringify(questions));
  if (image) {
    formData.append('file', image);
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/create/${adminId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.success) {
      localStorage.setItem("surveyToken", response.data.token);
      setTimeout(() => {
        setIsLoading(false); // Hide the loading overlay
        onSurveyCreated();
        window.location.reload();
      }, 2000); // Display the overlay for 2 seconds
    }
  } catch (error) {
    console.error("Error creating the survey:", error);
    toast.error("Failed to create survey");
    setIsLoading(false); // Hide the loading overlay in case of error
  }
};

  return (
    <div className="relative">
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
          <button disabled type="button" className="py-2.5 px-5 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Création de l'enquête...
          </button>
        </div>
      )}
      <div className="flex flex-row w-full" style={{ minHeight: "83.5vh" }}>
        <div className="w-full overflow-y-auto" style={{ maxHeight: "79vh" }}>
          <h2 className="font-bold text-2xl mb-1 text-bsrate">Créer une nouvelle enquête</h2>
          <p className="text-slate-600 mb-4">
            Créez une nouvelle enquête pour collecter des données auprès de vos employés.
          </p>
          <form onSubmit={handleSubmit} className="max-h-screen">
            <h2 className="font-semibold text-md mb-3 mt-5 text-bsrate">Ajouter une image</h2>
            <input type="file"
              className="file-input file-input-bordered w-full max-w-xs mb-6"
              onChange={handleSurveyImage} />
            <input
              type="text"
              value={surveyTitle}
              className="input text-sm input-bordered w-full mb-3"
              onChange={(e) => setSurveyTitle(e.target.value)}
              placeholder="Nom de l'enquête"
              required
            />
            <select
              value={surveyCategory}
              onChange={(e) => setSurveyCategory(e.target.value)}
              className="select select-bordered w-full mb-3"
              required
            >
              <option value="" disabled>Catégorie</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>{category}</option>
              ))}
            </select>
            <textarea
              value={surveyDescription}
              className="textarea textarea-bordered w-full mb-3"
              onChange={(e) => setSurveyDescription(e.target.value)}
              placeholder="Description"
              required
            />
            <h2 className="font-semibold text-md mb-3 text-bsrate">Les questions</h2>
            {questions.map((question, index) => (
              <div key={index} className="border-b border-slate-300 mb-4 pb-4">
                <input
                  type="text"
                  value={question.Question_title}
                  onChange={(e) => handleQuestionChange(index, "Question_title", e.target.value)}
                  placeholder="Titre de la question"
                  className="input w-full input-bordered text-sm mt-2 mb-2"
                  required
                />
                <select
                  value={question.Type}
                  onChange={(e) => handleQuestionChange(index, "Type", e.target.value)}
                  className="select select-bordered w-full max-w-xs"
                  required
                >
                  <option value="default">Type de question</option>
                  <option value="input">Texte (1 ligne)</option>
                  <option value="textarea">Paragraphe</option>
                  <option value="yes/no">Oui / Non</option>
                  <option value="rating">Note</option>
                </select>
                {question.Type === "rating" && (
                  <div className="flex flex-row gap-4 mt-2">
                    <input
                      type="text"
                      value={question.Lower_label}
                      onChange={(e) => handleQuestionChange(index, "Lower_label", e.target.value)}
                      placeholder="Lower label"
                      className="input input-bordered w-full max-w-xs text-sm"
                      required
                    />
                    <input
                      type="text"
                      value={question.Upper_label}
                      onChange={(e) => handleQuestionChange(index, "Upper_label", e.target.value)}
                      placeholder="Upper label"
                      className="input input-bordered w-full max-w-xs text-sm"
                      required
                    />
                  </div>
                )}
              </div>
            ))}
            <div className="w-full">
              <button type="button" onClick={addQuestion} className="btn">
                <PlusIcon className="w-4 h-4"></PlusIcon> Ajouter une question
              </button>
            </div>
            <div className="mt-4 flex gap-2">
              <button type="submit" className="btn btn-primary text-white bg-bsrate">
                <DocumentCheckIcon className="w-4 h-4"></DocumentCheckIcon> Créer le questionnaire
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateSurveyForm;
