import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';

const DEFAULT_COLORS = ['#675DF5', '#8D85FF', '#7874B6', '#443BC4'];
const GREY_COLOR = '#D3D3D3';

const ContractTypeDistributionChart = () => {
    const [contractTypeData, setContractTypeData] = useState([]);
    const companyId = localStorage.getItem("companyId");

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v2/employees/company/${companyId}/employees`);
                const employees = response.data.employees.filter(employee => !employee.isDeleted);
                const contractTypeCounts = employees.reduce((acc, curr) => {
                    const type = curr.contractType || 'Pas de données';
                    acc[type] = (acc[type] || 0) + 1;
                    return acc;
                }, {});

                const formattedData = [
                    ['Contract Type', 'Count'],
                    ...Object.entries(contractTypeCounts).map(([key, value]) => [key, value])
                ];

                setContractTypeData(formattedData);
            } catch (error) {
                console.error('Failed to fetch employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    const getColorArray = (data) => {
        const colorMap = new Map();
        let colorIndex = 0;

        data.slice(1).forEach(item => {
            const type = item[0];
            if (type === 'Pas de données') {
                colorMap.set(type, GREY_COLOR);
            } else if (!colorMap.has(type)) {
                colorMap.set(type, DEFAULT_COLORS[colorIndex % DEFAULT_COLORS.length]);
                colorIndex++;
            }
        });

        return data.slice(1).map(item => colorMap.get(item[0]));
    };

    const colors = getColorArray(contractTypeData);

    return (
        <div className="chart-container" style={{ width: '22vw', margin: '0 auto', padding: '20px' }}>
            <h2 className="text-center mb-1 font-semibold">Types de Contrats</h2>
            <p className='text-center text-sm text-slate-700'>Répartition des types de contrats</p>
            <Chart
                chartType="PieChart"
                data={contractTypeData}
                options={{
                    pieHole: 0.4,
                    legend: {
                        position: 'bottom', // Position the legend at the bottom
                        alignment: 'center', // Align legend to the center
                        maxLines: 2 // Allow legend to wrap into multiple lines
                    },
                    chartArea: { width: '90%', height: '80%' }, // Adjust chart area to add space for legend
                    colors: colors
                }}
                width="100%"
                height="300px"
            />
        </div>
    );
};

export default ContractTypeDistributionChart;
