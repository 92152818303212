import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentRequired = () => {
  const navigate = useNavigate();

  const handleProceedToPayment = async () => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');

    if (!token || !email) {
      navigate('/signin');
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v2/admin/create-checkout-session`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });

        if (error) {
          console.error('Stripe checkout error', error);
        }
      }
    } catch (error) {
      console.error('Error initiating Stripe checkout:', error);
    }
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('userId');
    localStorage.removeItem('companyId');
    localStorage.removeItem('companyName');
    navigate('/signin');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-bsrate2">
      <div className="bg-white rounded-lg shadow-lg p-8 text-center">
        <h1 className="text-2xl font-semibold mb-4">Abonnement requis</h1>
        <p className="mb-6">Vous devez être abonné à BackstageRate pour accéder à l'application.</p>
        <button
          onClick={handleProceedToPayment}
          className="btn btn-primary w-full mb-4"
        >
          Continuer vers le paiement
        </button>
        <button
          onClick={handleSignOut}
          className="btn btn-secondary w-full"
        >
         Se déconnecter
        </button>
      </div>
    </div>
  );
};

export default PaymentRequired;
