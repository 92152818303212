import React, { useEffect, useState } from "react";
import Sidebar2 from "../components/Base/Sidebar2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SurveyResultsModal from "../components/Surveys/SurveyResultsModal";
import Select from "react-select";
import moment from "moment";
import 'moment/locale/fr';

moment.locale('fr');

const MySurveys = () => {
  const navigate = useNavigate();
  const [surveysSend, setSurveysSend] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const adminId = localStorage.getItem("userId");
  const [selectedSentSurvey, setSelectedSentSurvey] = useState(null);

  const handleOpenResultModal = (sentSurvey) => {
    setSelectedSentSurvey(sentSurvey);
    setIsModalOpen(true);
  };

  const handleCloseResultModal = () => {
    setIsModalOpen(false);
    setSelectedSentSurvey(null);
  };

  useEffect(() => {
    const fetchingSendedSurvey = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/sended/${adminId}`
        );
        setSurveysSend(response.data.surveys);
        console.log(response.data.surveys);
      } catch (error) {
        console.error("Error", error.message);
      }
    };
    fetchingSendedSurvey();
  }, [adminId]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions);
  };

  const handleMonthChange = (selectedOptions) => {
    setSelectedMonths(selectedOptions);
  };

  const categories = Array.from(new Set(surveysSend.map(survey => survey.category)))
    .map(category => ({ value: category, label: category }));

  const months = Array.from(new Set(surveysSend.map(survey => moment(survey.sendDate).format("MMMM YYYY"))))
    .map(month => ({ value: month, label: month }));

  const filteredSurveys = surveysSend.filter(survey => {
    const matchesSearchTerm = searchTerm === "" || 
      (survey.surveyName && survey.surveyName.toLowerCase().includes(searchTerm)) || 
      (survey.surveyNickname && survey.surveyNickname.toLowerCase().includes(searchTerm));
    const matchesCategory = selectedCategories.length === 0 || 
      selectedCategories.some(category => category.value === survey.category);
    const matchesMonth = selectedMonths.length === 0 || 
      selectedMonths.some(month => moment(survey.sendDate).format("MMMM YYYY") === month.value);
    return matchesSearchTerm && matchesCategory && matchesMonth;
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#ffffff',
      borderColor: '#d1d5db',
      minHeight: '2.5rem',
      borderRadius: '0.5rem',
      CursorArrowRaysIcon: 'pointer',
      fontSize: '0.875rem',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#9ca3af',
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e5e7eb',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#374151',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9ca3af',
    }),
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const groupedSurveys = filteredSurveys.reduce((groups, survey) => {
    const month = moment(survey.sendDate).format("MMMM YYYY");
    if (!groups[month]) {
      groups[month] = [];
    }
    groups[month].push(survey);
    return groups;
  }, {});

  const sortedGroupedSurveys = Object.keys(groupedSurveys).sort((a, b) => moment(b, "MMMM YYYY") - moment(a, "MMMM YYYY"));

  return (
    <Sidebar2 title="Mes enquêtes">
      <div className='mt-3 ml-1'>
        <h1 className="font-bold text-3xl mb-1 text-bsrate">Enquêtes réalisées</h1>
        <p className="text-md mb-5 text-black">Toutes les enquêtes envoyées à vos employés.</p>
        <div className="px-1 grid grid-cols-4 gap-4">
          <input
            type="text"
            className="input input-sm input-bordered w-full h-10 mb-4"
            placeholder="Recherchez une enquête..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <Select
            isMulti
            options={categories}
            className="basic-multi-select mb-4"
            classNamePrefix="select"
            placeholder="Filtrer par catégorie"
            onChange={handleCategoryChange}
            styles={customStyles}
          />
          <Select
            isMulti
            options={months}
            className="basic-multi-select mb-4"
            classNamePrefix="select"
            placeholder="Filtrer par mois"
            onChange={handleMonthChange}
            styles={customStyles}
          />
        </div>
        {sortedGroupedSurveys.map(month => (
          <div key={month}>
            <h2 className="text-lg mt-2 font-bold mb-4">{capitalizeFirstLetter(month)}</h2>
            <div className="grid grid-cols-3 gap-4 mb-6">
              {groupedSurveys[month].map((survey, index) => {
                const responseRate = survey.sent_to > 0 ? (survey.usedTokens.length / survey.sent_to) * 100 : 0;
                return (
                  <div className="card bg-base-100 border border-slate-100 hover:shadow transition" key={index} style={{ margin: "0 0px" }}>
                    <div className="p-6">
                      {survey.imageUrl && (
                        <img
                          src={survey.imageUrl}
                          alt={`${survey.surveyName} Image`}
                          className="h-32 w-full object-cover mb-4 rounded-lg"
                        />
                      )}
                      <p className="border mb-3 flex w-fit rounded-lg border-indigo-400 text-indigo-600 text-xs bg-opacity-100 px-2 py-1 font-normal">{survey?.category}</p>
                      <h2 className="text-md font-bold"> {survey?.surveyName}</h2>
                      <h2 className="text-md font-regular">{survey?.surveyNickname}</h2>
                      <p className="text-sm font-normal mt-3 mb-0.5 text-slate-600"> Envoyée le {moment(survey.sendDate).format("D MMMM YYYY")}</p>
                      <p className="text-sm font-medium mb-1 text-slate-600">
                        Taux de réponse : {Math.round(responseRate)}%
                      </p>
                      <progress
                        className="progress progress-bsrate w-full mb-0 mt-6"
                        value={responseRate}
                        max="100"
                      ></progress>
                      <div className="card-actions justify-start mt-2">
                        <button className="hover:text-white hover:bg-bsrate text-left text-sm bg-white border-bsrate w-fit px-2 py-1 text-bsrate font-semibold shadow-none border min-h-0 h-auto transition rounded-lg mt-1" onClick={() => handleOpenResultModal(survey)}>
                          Voir les résultats →
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {selectedSentSurvey && (
          <SurveyResultsModal 
            isOpen={isModalOpen}
            onClose={handleCloseResultModal}
            sentSurvey={selectedSentSurvey} 
          />
        )}
      </div>
    </Sidebar2>
  );
};

export default MySurveys;
