import React, { useEffect, useState } from 'react';
import { getAllTextAnswers } from '../../services/surveysService';
import { TagCloud } from 'react-tagcloud';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    color: 'black',
    padding: '20px',
    border: '0px solid #ddd',
    borderRadius: '8px',
    fontSize: '14px',
    minWidth: '500px',
    fontWeight: 'normal',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
  },
  [`& .MuiTooltip-arrow`]: {
    color: 'white',
  },
});

const WordCloudComponent = () => {
  const [words, setWords] = useState([]);
  const [wordSentences, setWordSentences] = useState({});
  const adminId = localStorage.getItem("userId"); // Retrieve userId from local storage and use it as adminId

  useEffect(() => {
    const fetchTextAnswers = async () => {
      if (!adminId) {
        console.error('Admin ID is missing');
        toast.error("Admin ID is missing");
        return;
      }

      try {
        const data = await getAllTextAnswers(adminId);
        const textAnswers = data.textAnswers;
        const wordCounts = getWordCounts(textAnswers);
        setWords(wordCounts);

        const sentences = getSentencesForWords(textAnswers);
        setWordSentences(sentences);
      } catch (error) {
        console.error('Failed to fetch text answers:', error);
        toast.error("Failed to fetch text answers");
      }
    };

    fetchTextAnswers();
  }, [adminId]);

  const sanitizeWord = (word) => {
    return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[.,]/g, "").toLowerCase();
  };

  const getWordCounts = (textAnswers) => {
    const wordCounts = {};
    const originalWords = {};

    textAnswers.forEach(answer => {
      const wordsArray = answer.split(/\s+/);
      wordsArray.forEach(word => {
        const sanitizedWord = sanitizeWord(word);
        if (sanitizedWord.length >= 3) {
          wordCounts[sanitizedWord] = (wordCounts[sanitizedWord] || 0) + 1;
          if (!originalWords[sanitizedWord]) {
            originalWords[sanitizedWord] = word.normalize("NFC"); // Preserve original word with accents
          }
        }
      });
    });

    return Object.keys(wordCounts).map(sanitizedWord => {
      const displayWord = originalWords[sanitizedWord].replace(/[.,]/g, ""); // Remove commas and dots for display
      return { value: displayWord, count: wordCounts[sanitizedWord] };
    });
  };

  const getSentencesForWords = (textAnswers) => {
    const sentences = {};

    textAnswers.forEach(answer => {
      const sanitizedAnswer = answer.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const wordsInSentence = sanitizedAnswer.split(/\s+/);
      wordsInSentence.forEach(word => {
        const sanitizedWord = sanitizeWord(word);
        if (sanitizedWord.length >= 3) {
          if (!sentences[sanitizedWord]) {
            sentences[sanitizedWord] = [];
          }
          sentences[sanitizedWord].push(answer);
        }
      });
    });

    return sentences;
  };

  const customRenderer = (tag, size, color) => {
    const sanitizedTagValue = sanitizeWord(tag.value);
    const sentences = wordSentences[sanitizedTagValue];
    const totalAnswers = sentences ? sentences.length : 0;
    const sentencesList = sentences ? sentences.map((sentence, index) => `<li key=${index}>${sentence}</li>`).join('') : '';
    const tooltipContent = totalAnswers > 0 
      ? `<div><strong>Réponses totales:</strong> ${totalAnswers}</div><ul>${sentencesList}</ul>` 
      : 'No sentences found.';

    return (
      <CustomTooltip key={tag.value} title={<div dangerouslySetInnerHTML={{ __html: tooltipContent }} />} arrow>
        <span
          style={{
            color: '#675df6',
            fontSize: `${size}px`,
            margin: '0 5px', // Add horizontal space
            display: 'inline-block', // Ensure words stay in a single line
            cursor: 'default', // Remove pointer cursor
            transition: 'color 0.3s', // Smooth color transition
            
          }}
          onMouseEnter={(e) => e.target.style.color = '#18171c'} // Change color on hover
          onMouseLeave={(e) => e.target.style.color = '#675df6'} // Revert color on mouse leave
        >
          {tag.value}
        </span>
      </CustomTooltip>
    );
  };

  return (
    <div className="word-cloud-container bg-white p-8 rounded-xl h-full">
      <h3 className="text-xl font-semibold text-bsrate mb-2">Les mots de vos enquêtes</h3>
      <TagCloud
        minSize={12}
        maxSize={35}
        tags={words}
        renderer={customRenderer}
      />
    </div>
  );
};

export default WordCloudComponent;
