import React from 'react';
import { HomeIcon, DocumentPlusIcon, UserPlusIcon, ChartPieIcon, BuildingOfficeIcon, DocumentDuplicateIcon, LifebuoyIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'

const TwoCards = () => {
    const card1 = {
        title: "Enquêtes pour les employés : Quelle fréquence pour un impact optimal?",
        description: "Mais quelle est la fréquence idéale pour envoyer des enquêtes de satisfaction courtes (5 à 6 questions) sur des thématiques différentes ?"
    };
        const card2 = {
        title: "Besoin d'aide ?",
        description: "Vous avez besoin d'aide pour réaliser une enquête ou une évaluation de performance ? Contactez notre service client."
    };


  
    return (
        <div className="flex flex-col md:flex-row w-full  rounded-lg overflow-hidden dark:border-gray-800 gap-4">
            <div className=" md:w-1/2  bg-white border-slate-200 flex flex-row border rounded-lg  ">
                <img src='images/homecards/6.jpg' alt='Onboarding Image' className='w-1/3 h-full object-cover' />
                <div className="flex flex-col p-4 pl-8">
                    <h3 className="font-bold text-sm mb-2 pt-2 text-slate-900">{card1.title}</h3>
                    <p className="text-sm text-slate-500 dark:text-gray-400">
                        {card1.description}
                    </p>
                    <a href="https://backstagerate.com/enquetes-pour-les-employes-quelle-frequence-pour-un-impact-optimal/" target="_blank" className="text-sm pt-2 text-indigo-600 hover:underline">Lire l'article →</a>
                </div>
            </div>
 <div className=" md:w-1/2 bg-white border-slate-200 flex flex-row border rounded-lg  ">
                <img src='images/dash-1.png' alt='Onboarding Image' className='w-1/3 h-full object-cover rounded-tl rounded-bl' />
                <div className="flex flex-col p-4 pl-8">
                    <h3 className="font-bold text-sm mb-2 pt-1 text-slate-900">{card2.title}</h3>
                    <p className="text-sm text-slate-500 dark:text-gray-400">
                        {card2.description}
                    </p>
                <a href="/help" className="text-sm pt-2 text-indigo-600 hover:underline">Nous contacter →</a>
                </div>
            </div>
           
           
        </div>
    );
};

export default TwoCards;
