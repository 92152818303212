import React, { useState, useEffect } from "react";
import DaisyRating from "./Stars";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EyeSlashIcon } from '@heroicons/react/24/outline';

const SurveyQuestions = () => {
  const { surveyId, token, employeeId, surveySendId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [rating, setRating] = useState(2.5); // Set initial rating to 2.5
  const [questionIds, setQuestionIds] = useState([]);
  const [survey, setSurvey] = useState({});
  const [inputAns, setInputAns] = useState("");
  const [textAreaAns, setTextAreaAns] = useState("");
  const [isOkay, setIsOkay] = useState(null);

  const navigate = useNavigate();

  const handleInputAns = (e) => {
    setInputAns(e.target.value);
  };

  const handleTextAns = (e) => {
    setTextAreaAns(e.target.value);
  };

  const handleYesNoAns = (e) => {
    const value = e.target.value;
    setIsOkay(value === "yes" ? true : false);
  };

  const handleNextClick = async () => {
    try {
      await handleSaveAnswers();
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setInputAns("");
        setTextAreaAns("");
        setIsOkay(null); // Reset yes/no answer
        setRating(2.5); // Reset rating to 2.5
      } else {
        navigate("/survey-end");
      }
    } catch (error) {
      console.error("Failed to save answer or navigate to the next question:", error);
    }
  };

  useEffect(() => {
    const fetchingSurveyQuestions = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/questions/${surveyId}`
        );
        const sortedQuestions = data.sort((a, b) => a.Question_order - b.Question_order);
        setQuestions(sortedQuestions);
      } catch (error) {
        console.error("Failed to fetch survey questions:", error);
        toast.error("Failed to fetch survey questions.");
      }
    };
    fetchingSurveyQuestions();
  }, [surveyId]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex < questions.length) {
      const currentQuestionId = questions[currentQuestionIndex]._id;
      if (!questionIds.includes(currentQuestionId)) {
        setQuestionIds((prev) => [...prev, currentQuestionId]);
      }
    }
    setRating(2.5); // Reset rating to 2.5 when question changes
  }, [currentQuestionIndex, questions]);

  const handleAnswerChange = (answer) => {
    setRating(answer);
  };

  const handleSaveAnswers = async () => {
    try {
      const currentQuestionId = questions[currentQuestionIndex]._id;
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/saveanswer/${surveyId}`,
        {
          questionId: currentQuestionId,
          rating,
          token,
          employeeId,
          inputAns,
          textAreaAns,
          isOkay,
          surveySendId
        }
      );
      setRating(2.5); // Reset rating after saving answer
      // toast.success("Answer saved successfully");
    } catch (error) {
      console.error("Failed to save answer:", error);
      toast.error("Failed to save answer");
    }
  };

  useEffect(() => {
    const fetchingSurvey = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/surveybyid/${surveyId}`
        );
        if (response) {
          setSurvey(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch survey:", error);
        toast.error("Failed to fetch survey.");
      }
    };
    fetchingSurvey();
  }, [surveyId]);

  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <div className='p-6 pt-3 w-full flex flex-col justify-center items-center h-screen bg-bsrate2'>
      <img src='/images/bsr-survey.png' alt='survey' className='w-60 mb-2' />
      <div className='badge mb-3 text-center border-slate-200 text-xs p-3 bg-slate-50 text-slate-500'>
        <EyeSlashIcon className='w-4 h-4 mr-2' /> Vos réponses sont anonymes
      </div>
      <div className='flex flex-col  h-full w-full px-6 rounded-lg shadow align-top top-0 items-center justify-center bg-white'>
        {questions.length > 0 && (
          <>
            <div className="flex flex-col w-2/3 justify-center items-center align-middle">
              <p className='text-md border badge p-2 border-indigo-300 text-indigo-600 w-fit justify-center align-middle items-center mb-0'>{survey?.name}</p>
              <p className="font-semibold text-2xl uppercase text-sm text-slate-400 mt-4">
                {/* <span>{questions[currentQuestionIndex].Question_category}</span> */}
              </p>
              <p className="font-bold text-bsrate text-3xl mt-2 mb-8 justify-center text-center">
                {questions[currentQuestionIndex].Question_title}
              </p>
              {questions[currentQuestionIndex].Type === "rating" ? (
                <DaisyRating
                  value={rating * 2} // Pass the rating value multiplied by 2 (to match the internal value scale)
                  onChange={handleAnswerChange}
                  lowerLabel={questions[currentQuestionIndex].Lower_label}
                  upperLabel={questions[currentQuestionIndex].Upper_label}
                />
              ) : questions[currentQuestionIndex].Type === "input" ? (
                <input
                  type="text"
                  onChange={handleInputAns}
                  value={inputAns}
                  placeholder="Écrivez votre réponse..."
                  className="input input-bordered w-full max-w-xs"
                />
              ) : questions[currentQuestionIndex].Type === "textarea" ? (
                <textarea
                  onChange={handleTextAns}
                  value={textAreaAns}
                  placeholder="Écrivez votre réponse..."
                  type="textarea"
                  className="input input-bordered w-full max-w-md min-h-32 pt-2"
                />
              ) : questions[currentQuestionIndex].Type === "yes/no" ? (
                <div className="flex gap-7 justify-center w-full">
                  <label className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="yes-no"
                      value="yes"
                      checked={isOkay === true}
                      onChange={handleYesNoAns}
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-lg text-slate-700">Oui</span>
                  </label>
                  <label className="flex items-center space-x-3">
                    <input
                      type="radio"
                      name="yes-no"
                      value="no"
                      checked={isOkay === false}
                      onChange={handleYesNoAns}
                      className="form-radio h-5 w-5 text-indigo-600"
                    />
                    <span className="text-lg text-slate-700">Non</span>
                  </label>
                </div>
              ) : null}
            </div>
          </>
        )}
        <button
          className='btn btn-primary bg-bsrate text-white mb-6 w-72 absolute bottom-0 bottom-0 md:bottom-14'
          onClick={handleNextClick}
        >
          {currentQuestionIndex < questions.length - 1 ? "Question suivante →" : "Terminer l'enquête"}
        </button>

        <div className="w-full mt-4 absolute bottom-6 px-6">
          <p className="text-center mt-2 mb-2.5 text-bsrate text-sm">{currentQuestionIndex + 1}/{questions.length}</p>
          <div className="w-full bg-gray-200 rounded-sm h-2">
            <div className="bg-bsrate h-2 rounded-sm" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyQuestions;
