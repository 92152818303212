import React from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { CheckIcon } from '@heroicons/react/20/solid';

// Make sure the public key is correctly set
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const includedFeatures = [
  'Envoie de sondages illimités',
  'Accès aux 21 sondages BackstageRate',
  'Activation du Tableau de Bord',
  'Activation de l’analyse de données',
  'Accès à un service client privilégié',
  'Aide à la prise en main',
  // 'Assistance rapide',
  // 'Accès à des ressources pour une utilisation optimale',
  'Pas de limite dans le nombre d’employés',
];

const Checkout = ({ email }) => {
    const handleCheckout = async () => {
        try {
            const token = localStorage.getItem("token");
            console.log("Starting checkout process for email:", email);

            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/v2/admin/create-checkout-session`,
                { email }, // Pass the email in the request body
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            console.log("Checkout session response:", response.data);

            if (response.data && response.data.id) {
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: response.data.id,
                });

                if (error) {
                    console.error('Stripe checkout error', error);
                }
            }
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };

    return (
        <div>
            <div className="mt-0 w-full flex flex-col rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex">
                <div className="p-5 sm:p-8 lg:flex-auto">
                    <h3 className="text-xl font-bold tracking-tight text-gray-900">Abonnement à BackstageRate</h3>
                    <div className="mt-1 flex items-center gap-x-4">
                        <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Ce qui est inclus</h4>
                        <div className="h-px flex-auto bg-gray-100" />
                    </div>
                    <ul
                        role="list"
                        className="mt-4 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-1 sm:gap-2"
                    >
                        {includedFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                                <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="-mt-2 px-6 lg:mt-0 w-full h-fit">
                    <div className="rounded-2xl -mt-3 mb-4 bg-gray-50 py-12 text-center ring-1 ring-inset ring-gray-900/5 lg:py-4">
                        <div className="mx-auto px-6">
                            <p className="mt-0 flex items-baseline justify-start gap-x-0">
                                <span className="text-5xl font-bold tracking-tight text-gray-900">99</span>
                                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">€</span>
                                <p className="ml-4 text-base justify-start text-left font-semibold text-gray-600">Facturation mensuelle</p>
                            </p>
                            <button
                                onClick={handleCheckout}
                                className="mt-4 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                S'abonner
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Checkout;
