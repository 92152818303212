// components/GenericModal.js
import React from 'react';

const GenericModal2 = ({ isOpen, title, children, closeModal }) => {
  if (!isOpen) return null;  // Ensure nothing is rendered when modal is not open

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 min-h-44  " onClick={closeModal}></div>
      <dialog className="modal smooth-open min-h-44 overflow-scroll h-full " open={isOpen}>
        <div className="bg-white rounded-xl h-fit result-modal relative z-50 max-w-2/4 w-2/4 mt-4 mb-4 ">
          <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={closeModal}>✕</button>
          <div  className='bg-bsrate text-white p-6 pb-4 rounded-t-xl '>
          Résultats de l'enquête
            <h2 className="font-bold text-2xl">{title}</h2>
            </div>
         
            {children}
         
        </div>
      </dialog>
    </>
  );
};

export default GenericModal2;
