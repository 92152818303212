import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getAverageRatingByMonth } from '../../services/surveysService';
import { toast } from 'react-toastify';

const MonthlyAverageRatingChart = ({ category }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Note moyenne',
        data: [],
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  });

  const adminId = localStorage.getItem("userId"); // Retrieve userId from local storage and use it as adminId

  useEffect(() => {
    const fetchAverageRatingByMonth = async () => {
      if (!adminId) {
        console.error('Admin ID is missing');
        toast.error("Admin ID is missing");
        return;
      }

      try {
        const data = await getAverageRatingByMonth(category, adminId); // Pass adminId to the service
        console.log('Fetched data:', data); // Log fetched data

        if (!data || !data.monthlyRatings || data.monthlyRatings.length === 0) {
          console.log('No ratings found');
          return;
        }

        const months = data.monthlyRatings.map(item => {
          const [year, month] = item.month.split('-');
          return new Date(year, month - 1).toLocaleString('default', { month: 'short', year: 'numeric' });
        });

        const ratings = data.monthlyRatings.map(item => parseFloat(item.averageRating));

        console.log('Months:', months); // Log months
        console.log('Ratings:', ratings); // Log ratings

        setChartData({
          labels: months,
          datasets: [
            {
              label: 'Note moyenne',
              data: ratings,
              backgroundColor: '#675df6',
              borderColor: '#675df6',
              borderWidth: 0,
            },
          ],
        });
      } catch (error) {
        console.error('Failed to fetch average rating by month:', error);
        toast.error('Failed to fetch average rating by month');
      }
    };

    fetchAverageRatingByMonth();
  }, [category, adminId]);

  return (
    <div className="flex flex-col w-full p-4 bg-card border border-bsrate shadow rounded-lg">
      <h3 className="text-sm font-semibold text-bsrate mb-2">Note moyenne par mois</h3>
      <Bar data={chartData} />
    </div>
  );
};

export default MonthlyAverageRatingChart;
