import React from 'react';

const NewSurveyModal = ({ isOpen, title, children, closeModal }) => {
  if (!isOpen) return null;  // Ensure nothing is rendered when modal is not open

  return (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-50"
        onClick={closeModal}
      ></div>
      <dialog className="modal smooth-open fixed inset-0 flex items-center justify-center z-50" open={isOpen}>
        <div
          className="bg-white relative z-50 min-h-96 w-3/6 h-5/6 max-w-2/3 rounded-2xl shadow-lg p-4"
          onClick={(e) => e.stopPropagation()} // Prevent click inside the modal from closing it
        >
          <button
            className="btn btn-sm btn-circle absolute right-2 top-2 z-60"
            onClick={closeModal}
          >
            ✕
          </button>
          <div className="h-screen p-4">
            {children}
          </div>
        </div>
      </dialog>
    </>
  );
};

export default NewSurveyModal;
