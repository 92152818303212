import React from 'react';
import Sidebar2 from '../components/Base/Sidebar2';

const Help = () => {
    return (
        <Sidebar2 title="Besoin d'aide ?">
            <div className="">
                <div className='mt-3 ml-1'>
                    <h1 className="font-bold text-3xl mb-1 text-bsrate">Besoin d'aide ?</h1>
                    <p className="text-md mb-4 text-black">Contactez-nous.</p>
                    <p className="text-md mb-4 text-black">
                        Si vous avez des questions ou avez besoin d'assistance, veuillez nous contacter à <a href="mailto:contact@backstagerate.com" className="text-bsrate underline">contact@backstagerate.com</a>.
                    </p>
                </div>
                <div className='w-full pt-2'>
                    <div className='w-4/4 px-2'>
                    </div>
                </div>
            </div>
        </Sidebar2>
    );
};

export default Help;
