import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import Onboarding from "./pages/Onboarding";
import Dashboard from "./pages/Dashboard";
import ResetPassword from "./components/Auth/ResetPassword";
import ChangePassword from "./components/Auth/ChangePassword";
import Employees from "./pages/Employees";
import Help from "./pages/Help";
import MySurveys from "./pages/MySurveys";
import NewSurvey from "./pages/NewSurvey";
import Settings from "./pages/Settings";
import Synthesis from "./pages/Synthesis";
import MyAccount from "./pages/MyAccount";
import { AuthProvider } from "./authContext";
import ProtectedRoute from "./ProtectedRoute";
import PrivateRoute from "./PrivateRoute";
import ServeyQuestions from "./components/servey-questions/ServeyQuestions";
import SendSurveyMails from "./components/Surveys/SendSurveyMails";
import { SurveyProvider } from "./surveyContext";
import AnswerDetails from "./pages/AnswerDetails";
import SurveyQuestionPage from "./pages/SurveyQuestionPage";
import SurveyEnd from "./pages/SurveyEnd";
import { CompanyProvider } from "./services/CompanyContext";
import { UserProvider } from "./services/UserContext";
import OnboardingSuccess from "./pages/OnboardingSuccess";
import PaymentRequired from "./pages/PaymentRequired";

const App = () => {
    return (
        <AuthProvider>
            <SurveyProvider>
                <CompanyProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/signin" element={<SignInPage />} />
                                <Route path="/signup" element={<SignUpPage />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/admin/reset-new-password/:token" element={<ChangePassword />} />
                                <Route path="/send-survey-mails" element={<SendSurveyMails />} />
                                <Route path="/onboarding" element={<Onboarding />} />
                                <Route path="/onboarding/success" element={<OnboardingSuccess />} />
                                <Route path="/payment-required" element={<PaymentRequired />} />
                                {/* Protected routes */}
                                <Route path="/" element={<ProtectedRoute element={Dashboard} />} />
                                <Route path="/surveys" element={<ProtectedRoute element={MySurveys} />} />
                                <Route path="/new-survey" element={<ProtectedRoute element={NewSurvey} />} />
                                <Route path="/settings" element={<ProtectedRoute element={Settings} />} />
                                <Route path="/synthesis" element={<ProtectedRoute element={Synthesis} />} />
                                <Route path="/my-account" element={<ProtectedRoute element={MyAccount} />} />
                                <Route path="/help" element={<ProtectedRoute element={Help} />} />
                                <Route path="/employees" element={<ProtectedRoute element={Employees} />} />

                                {/* Non-protected routes for surveys */}
                                <Route path="/surveys/:adminId/:surveyId/:surveySendId/:employeeId/:token" element={<SurveyQuestionPage />} />
                                <Route path="/surveys/:surveyId/:surveySendId/:employeeId/:token" element={<ServeyQuestions />} />
                                <Route path="/answer-details/:surveySendId" element={<AnswerDetails />} />
                                <Route path="/survey-end" element={<SurveyEnd />} />
                            </Routes>
                        </BrowserRouter>
                    </UserProvider>
                </CompanyProvider>
            </SurveyProvider>
        </AuthProvider>
    );
};

export default App;
