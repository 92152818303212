import React from 'react'
import { Link } from 'react-router-dom'
import { EyeSlashIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';


const SendSurveyMails = () => {
  return (
    <div className='flex items-center justify-center h-screen flex-col'>
                      <CheckBadgeIcon className='w-12 h-12 mb-6 -mt-8 mr-2 text-bsrate'></CheckBadgeIcon>

      <h1 className='font-semibold text-[20px]'>Votre enquête a bien été envoyée !</h1>
      <Link to='/' className='btn bg-bsrate rounded-lg hover:bg-indigo-800 text-white mt-4  font-semibold'>Retour au Tableau de Bord</Link>
    </div>
  )
}

export default SendSurveyMails
