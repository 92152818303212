import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Checkout from "../components/Auth/Checkout";
import { loadStripe } from '@stripe/stripe-js'; // Import loadStripe

const inputStyle =
    "flex mt-0.5 h-10 w-full rounded-md border border-input bg-background px-3 py-2.5 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";
const halfInputStyle =
    "input border border-slate-200 w-full";
const buttonStyle =
    "btn btn-primary bg-bsrate border-0 items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-primary-foreground hover:bg-indigo-700 h-10 px-4 py-2 w-full";
const selectStyle =
    "flex h-10 mt-0.5 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY); // Load Stripe with your public key

const Onboarding = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: "",
        siret: "",
        address: "",
        city: "",
        postalCode: "",
        numOfEmployees: "",
        firstName: "",
        lastName: "",
        phone: "",
        position: "",
        howDidYouKnow: "",
        email: "", // Ensure email is part of the form data
        acceptTerms: false,
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        if (storedEmail) {
            setFormData((prevFormData) => ({ ...prevFormData, email: storedEmail }));
        }
    }, []);

    const handleChange = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!userId || !token) {
            toast.error("User session error. Please sign up again.");
            navigate("/signup");
            setLoading(false);
            return;
        }

        if (step === 1) {
            const onboardingData = {
                name: formData.name,
                siret: formData.siret,
                address: formData.address,
                city: formData.city,
                postalCode: formData.postalCode,
                numOfEmployees: formData.numOfEmployees,
            };

            const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v2/companies/register-company/${userId}`;

            try {
                const response = await axios.post(endpoint, onboardingData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response && response.data) {
                    localStorage.setItem("companyId", response.data.company._id);
                    setStep(2);
                } else {
                    toast.error(response.data.msg || "Failed to complete onboarding.");
                }
            } catch (error) {
                toast.error(
                    error.response?.data?.msg || "Failed to complete onboarding process."
                );
            } finally {
                setLoading(false);
            }
        } else if (step === 2) {
            remainingDetails();
        }
    };

    const remainingDetails = async () => {
        if (!formData.acceptTerms) {
            toast.error("You must accept terms and conditions to proceed.");
            return;
        }

        const step2Data = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
            position: formData.position,
            howDidYouKnow: formData.howDidYouKnow,
            email: formData.email, // Include email
        };

        const endpoint = `${process.env.REACT_APP_BACKEND_URL}/api/v2/admin/register/step-two/${userId}`;

        try {
            const response = await axios.post(endpoint, step2Data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response) {
                setStep(3); // Move to the payment step
            }
        } catch (error) {
            toast.error(
                error.response?.data?.msg || "Failed to update user details."
            );
        }
    };

    const step1Fields = [
        { key: "name", label: "Nom de l'entreprise", placeholder: "Nom", style: halfInputStyle },
        { key: "siret", label: "Votre numéro SIRET", placeholder: "Numéro SIRET", style: halfInputStyle },
        { key: "address", label: "Votre adresse", placeholder: "Adresse", style: halfInputStyle },
        { key: "city", label: "Votre ville", placeholder: "Ville", style: halfInputStyle },
        { key: "postalCode", label: "Code Postal", placeholder: "Code Postal", style: halfInputStyle },
        { key: "numOfEmployees", label: "Nombre d'employés", placeholder: "Entrez le nombre d'employés", style: selectStyle, type: "select", options: ["1-49", "50-99", "100-249", "250+"] },
    ];

    const step2Fields = [
        { key: "firstName", label: "Prénom", placeholder: "Votre prénom", style: halfInputStyle },
        { key: "lastName", label: "Nom", placeholder: "Votre nom", style: halfInputStyle },
        { key: "phone", label: "Téléphone", placeholder: "Numéro de téléphone", style: halfInputStyle },
        { key: "position", label: "Poste", placeholder: "Entrez votre poste", style: halfInputStyle },
        { key: "howDidYouKnow", label: "Comment avez-vous connu Backstage Rate ?", placeholder: "Entrez votre réponse", style: selectStyle, type: "select", options: ["Presse", "Réseaux sociaux", "Google"] },
        // { key: "email", label: "Email", placeholder: "Votre adresse email", style: halfInputStyle, disabled: true }, // Add email field, disabled to prevent editing
    ];

    const renderField = (field) => (
        <div className="grid gap-2" key={field.key}>
            <label className="text-sm font-medium leading-none" htmlFor={field.key}>
                {field.label}
            </label>
            {field.type === "select" ? (
                <select
                    id={field.key}
                    value={formData[field.key]}
                    onChange={(e) => handleChange(field.key, e.target.value)}
                    required
                    className={field.style}
                >
                    <option value="">{field.placeholder}</option>
                    {field.options.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    type={field.type || "text"}
                    id={field.key}
                    value={formData[field.key]}
                    onChange={(e) => handleChange(field.key, e.target.value)}
                    placeholder={field.placeholder}
                    required
                    className={field.style}
                    disabled={field.disabled} // Add disabled attribute
                />
            )}
        </div>
    );

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-slate-100">
            <div className="flex flex-grow items-center justify-center w-3/4 h-16 py-12 ">
                <div className="hidden md:block w-2/3 h-full bg-slate-400 rounded-l-lg ">
                    <img src="images/onboarding-img.jpg" alt="BackstageRate Logo" className="w-full h-full object-cover rounded-l-lg" />
                </div>
                <div className="flex flex-col pb-4 bg-white h-full flex-grow rounded-r-lg bg-card shadow-sm text-card-foreground p-1 w-2/3 justify-between">
                    <form onSubmit={handleSubmit}>
                        <img src="images/bsr-logo.png" alt="BackstageRate Logo" className="w-40 mr-auto ml-5 mt-6 -mb-2" />

                        <div className="flex flex-col space-y-1.5 p-6 pt-5 pb-4">
                            <h1 className="whitespace-nowrap font-semibold tracking-tight text-2xl">
                                Bienvenue sur Backstage Rate !
                            </h1>
                            <p className="text-sm text-muted-foreground">
                                Améliorez la qualité de travail de votre entreprise.
                            </p>
                        </div>
                        <div className="flex flex-col flex-grow justify-between">
                            <div>
                                <div className="p-6 pt-2 space-y-4">
                                    {step === 1 && (
                                        <>
                                            {step1Fields.slice(0, 3).map(renderField)}
                                            <div className="flex space-x-4">
                                                <div className="w-1/2">
                                                    {renderField(step1Fields[3])}
                                                </div>
                                                <div className="w-1/2">
                                                    {renderField(step1Fields[4])}
                                                </div>
                                            </div>
                                            {renderField(step1Fields[5])}
                                        </>
                                    )}
                                    {step === 2 && (
                                        <>
                                            <div className="flex space-x-4">
                                                <div className="w-1/2">
                                                    {renderField(step2Fields[0])}
                                                </div>
                                                <div className="w-1/2">
                                                    {renderField(step2Fields[1])}
                                                </div>
                                            </div>
                                            {step2Fields.slice(2).map(renderField)}
                                            <div className="px-0 pb-0">
                                                <label className="flex items-start">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData.acceptTerms}
                                                        className="mt-1"
                                                        onChange={(e) => handleChange("acceptTerms", e.target.checked)}
                                                        required
                                                    />
                                                    <span className="ml-2 text-sm">
                                                        En cochant cette case, je confirme que j'ai lu et que j'accepte les termes et conditions d'utilisation. 
                                                    </span>
                                                </label>
                                            </div>
                                        </>
                                    )}
                                    {step === 3 && (
                                        <Checkout email={formData.email} />
                                    )}
                                </div>
                                <div className="mt-0 px-6">
                                    {step === 1 && (
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className={`${buttonStyle} text-white`}
                                        >
                                            {loading ? "Loading..." : "Suivant →"}
                                        </button>
                                    )}
                                    {step === 2 && (
                                        <button
                                            type="button"
                                            disabled={loading}
                                            className={`${buttonStyle} text-white`}
                                            onClick={remainingDetails}
                                        >
                                            {loading ? "Loading..." : "Suivant →"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
};

export default Onboarding;
