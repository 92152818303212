import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext";

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { authData, loading } = useAuth();
    const [isPaid, setIsPaid] = useState(null);

    useEffect(() => {
        const checkPaymentStatus = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsPaid(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v2/admin/get-payment-status`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsPaid(data.isPaid);
                } else {
                    setIsPaid(false);
                }
            } catch (error) {
                console.error("Error checking payment status:", error);
                setIsPaid(false);
            }
        };

        if (authData) {
            checkPaymentStatus();
        } else {
            setIsPaid(false);
        }
    }, [authData]);

    if (loading || isPaid === null) {
        return <div></div>;
    }

    if (!authData) {
        return <Navigate to="/signin" />;
    }

    return isPaid ? <Component {...rest} /> : <Navigate to="/payment-required" />;
};

export default ProtectedRoute;
