import React, { useEffect, useState } from "react";
import axios from "axios";
import GenericModal2 from "../Base/GenericModal2";
import StarRating2 from "./StarRating2";
import StarRating from "./StarRating";

const SurveyResultsModal = ({ isOpen, onClose, sentSurvey }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalAnswers, setTotalAnswers] = useState(0);
  const [globalAverage, setGlobalAverage] = useState(0);

  useEffect(() => {
    if (isOpen && sentSurvey) {
      const fetchResults = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v2/surveys/survey/sent/results/${sentSurvey._id}`
          );
          const fetchedResults = response.data;

          console.log("Fetched Results:", fetchedResults); // Log the fetched results

          setResults(fetchedResults.results);
          setTotalAnswers(fetchedResults.totalAnswers);
          setGlobalAverage(fetchedResults.globalAverageRating);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching survey results:", error);
          setLoading(false);
        }
      };
      fetchResults();
    }
  }, [isOpen, sentSurvey]);

  if (!sentSurvey) {
    return null;
  }

  const responseRate = sentSurvey.sent_to > 0 ? (totalAnswers / sentSurvey.sent_to) * 100 : 0;
  const isValidResponseRate = totalAnswers >= 3;

  console.log("Total Answers:", totalAnswers);
  console.log("Sent To:", sentSurvey.sent_to);
  console.log("Response Rate:", responseRate);

  return (
    <GenericModal2 isOpen={isOpen} title={sentSurvey.surveyNickname || "Survey Results"} closeModal={onClose}>
      {loading ? (
        <p>Loading...</p>
      ) : !isValidResponseRate ? (
        <div className="flex items-top mt-10 justify-center h-5/6">
          <div className="flex flex-col items-center">
            <div role="alert" className="alert">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
              </svg>
              <span>Pas encore assez de réponses pour cette enquête. <br />
                Il faut au minimum 3 réponses pour afficher les résultats.</span>
            </div>
          </div>
        </div>
      ) : results.length > 0 ? (
        <>
          <div className="mb-4 border-b bg-bsrate border-slate-30 text-white p-6 pt-0 pb-4 ">
            <div className="flex flex-row justify-between pt-4">
              <div>
                <h3 className="font-regular pb-2">Note moyenne</h3>
                <h3 className="flex flex-row font-semibold mb-4 text-white text-lg">
                  <StarRating2 averageRating={globalAverage} /> {globalAverage}
                </h3>
              </div>
              <h3 className="font-regular">Réponses totales: {totalAnswers}</h3>
            </div>
          </div>
          <div className="p-6 pt-2 pb-4">
            {results.map((item, index) => (
              <div key={index} className="mb-4">
                <h3 className="font-regular text-lg">
                  {item.questionOrder ? `${item.questionOrder}. ` : ''}{item.questionTitle}
                </h3>
                {item.questionType === "rating" && (
                  <>
                    <p className="flex flex-row items-center">
                      <StarRating averageRating={item.averageRating} /> 
                      <span className="text-bsrate ml-2 font-semibold">{item.averageRating}</span>
                    </p>
                  </>
                )}
                {item.questionType === "input" && (
                  <ul>
                    {item.inputAnswers.map((answer, i) => (
                      <li key={i}>{answer}</li>
                    ))}
                  </ul>
                )}
                {item.questionType === "textarea" && (
                  <ul>
                    {item.textAreaAnswers.map((answer, i) => (
                      <li key={i}>{answer}</li>
                    ))}
                  </ul>
                )}
                {item.questionType === "yes/no" && (
                  <p>
                    {(() => {
                      const totalYesNo = item.isOkayAnswers.trueCount + item.isOkayAnswers.falseCount;
                      const yesPercentage = ((item.isOkayAnswers.trueCount / totalYesNo) * 100).toFixed(0);
                      const noPercentage = ((item.isOkayAnswers.falseCount / totalYesNo) * 100).toFixed(0);
                      return (
                        <>
                          <b>Oui:</b> {yesPercentage}% | <b>Non:</b> {noPercentage}%
                        </>
                      );
                    })()}
                  </p>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <p>No results available.</p>
      )}
    </GenericModal2>
  );
};

export default SurveyResultsModal;
