import React from 'react';
import { HomeIcon, DocumentPlusIcon, UserPlusIcon, ChartPieIcon, BuildingOfficeIcon, DocumentDuplicateIcon, LifebuoyIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/solid'

const TwoCards = () => {
    const card1 = {
        title: "Comment se lancer dans les enquêtes?",
        description: "Dans un monde du travail en constante évolution, il est crucial pour les entreprises de rester à l’écoute de leurs employés et de comprendre leurs besoins."
    };
        const card2 = {
        title: "Pourquoi privilégier des enquêtes courtes et régulières ?",
        description: "Si les enquêtes longues et détaillées peuvent être utiles dans certaines situations, il est important de privilégier des enquêtes courtes et régulières..."
    };


  
    return (
        <div className="flex flex-col md:flex-row  w-full  rounded-lg overflow-hidden dark:border-gray-800 gap-4">
            <div className=" md:w-1/2 bg-white border-slate-200 flex flex-row border rounded-lg  ">
                <img src='images/homecards/4.jpg' alt='Onboarding Image' className='w-1/3 h-full object-cover' />
                <div className="flex flex-col p-4 pl-8">
                    <h3 className="font-bold text-sm mb-2 pt-2 text-slate-900">{card1.title}</h3>
                    <p className="text-sm text-slate-500 dark:text-gray-400">
                        {card1.description}
                    </p>
                    <a href="https://backstagerate.com/comment-se-lancer-dans-les-enquetes/" target="_blank" className="text-sm pt-2 text-indigo-600 hover:underline">Lire l'article →</a>
                </div>
            </div>
 <div className=" md:w-1/2 bg-white border-slate-200 flex flex-row border rounded-lg  ">
                <img src='images/homecards/5.jpg' alt='Onboarding Image' className='w-1/3 h-full object-cover rounded-tl rounded-bl' />
                <div className="flex flex-col p-4 pl-8">
                    <h3 className="font-bold text-sm mb-2 pt-1 text-slate-900">{card2.title}</h3>
                    <p className="text-sm text-slate-500 dark:text-gray-400">
                        {card2.description}
                    </p>
                <a href="https://backstagerate.com/enquetes-courtes-et-regulieres/" target="_blank" className="text-sm pt-2 text-indigo-600 hover:underline">Lire l'article →</a>
                </div>
            </div>
           
           
        </div>
    );
};

export default TwoCards;
