import React from 'react';
import Sidebar from '../components/Base/Sidebar';
import PageTitle from '../components/Base/PageTitle';
import SettingsDashboard from '../components/Settings/SettingsList';
import SynthesisStats from '../components/Synthesis/SynthesisStats';
import Sidebar2 from '../components/Base/Sidebar2';
import LineChart from '../components/Dashboard/LineChart';
import TotalEmployeesStat from '../components/Stats/TotalEmployeesStat';
import GenderDistributionChart from '../components/Stats/GenderDistributionChart';
import DepartmentDistributionChart from '../components/Stats/DepartmentDistributionChart';
import StatsCompany from '../components/StatsModules/StatsCompany';
import CategoryAverageRating from '../components/Stats/CategoryAverageRating';
import CategoryAverageRating2 from '../components/Stats/CategoryAverageRating2';
import { ChevronDownIcon,  StarIcon, FaceSmileIcon, ChartBarIcon, HomeIcon, BuildingOffice2Icon, FilterIcon, DocumentDuplicateIcon, LifebuoyIcon, UsersIcon, Cog6ToothIcon } from '@heroicons/react/24/outline'
import TotalSentSurveysStatCurrentMonth from '../components/Stats/TotalSentSurveysStatCurrentMonth';

import MonthlyAverageRatingChart from '../components/Stats/MonthlyAverageRatingChart';
import CategorySurveyCount from '../components/Stats/CategorySurveyCount';
import WordCloudComponent from '../components/Stats/WordCloudComponent';
import StatsEngagement from '../components/StatsModules/StatsEngagement';
import StatsSatisfaction from '../components/StatsModules/StatsSatisfaction';
import StatsManagement from '../components/StatsModules/StatsManagement';
import StatsPerformances from '../components/StatsModules/StatsPerformances';
import StatsPolitique from '../components/StatsModules/StatsPolitique';
import StatsTeletravail from '../components/StatsModules/StatsTeletravail';
import AllSurveysAverageRating2 from '../components/StatsModules/AllSurveysAverageRating2';
import TotalSentSurveysStat2 from '../components/Stats/TotalSentSurveysStat2';

const chartData = {
  labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
  datasets: [
    {
      label: 'Daily Responses',
      data: [10, 20, 30, 20, 50, 60, 70],
      borderColor: 'black',  // Black color for the line
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      fill: false,
    }
  ]
};

const Synthesis = () => {
  return (
    <Sidebar2 title="Synthèse" >
      <div className='  mt-3 ml-1'>
            <h1 className="font-bold text-3xl mb-1 text-bsrate">Tableau de bord</h1>
                <p className=" text-md mb-4 text-black">Toutes les données de votre entreprise.</p>
      </div>
      
      <div className=' flex flex-col md:flex-row gap-4 mb-4'>

        <div className='grid grid-cols-1 md:w-1/4 gap-4'>
        <AllSurveysAverageRating2 />
        <TotalSentSurveysStatCurrentMonth />
        </div>

        <div className='md:w-3/4 grid grid-cols-2 md:grid-cols-3 gap-4'>

          <CategoryAverageRating2 category="Engagement"
          IconComponent={StarIcon} 
      subtitle="Niveau d'implication"/>
          <CategoryAverageRating2 category="Satisfaction"
          IconComponent={FaceSmileIcon} 
      subtitle="Avis des collaborateurs"/>
          <CategoryAverageRating2 category="Télétravail"
          IconComponent={HomeIcon} 
      subtitle="Nouveaux modes de travail"/>
          <CategoryAverageRating2 category="Performances"
          IconComponent={ChartBarIcon} 
      subtitle="Efficacité et résultats"/>
          <CategoryAverageRating2 category="Politique d'entreprise"
          IconComponent={BuildingOffice2Icon} 
      subtitle="Règles et culture interne"/>
          <CategoryAverageRating2 category="Management"
          IconComponent={UsersIcon} 
      subtitle="Qualité de la gestion"/>
          </div>
    </div>

      <div className="">

        <div className='gap-4'>
          <div className='w-full mb-4'>
            <StatsCompany />
          </div>
          <div className='w-full'>
            <WordCloudComponent />
            </div>
          
          </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-4'>
          
        <div className='w-full'>
          <StatsEngagement />
          </div>
          <div className='w-full'>
          <StatsSatisfaction />
          </div>
          <div className='w-full'>
          <StatsPerformances />
          </div>
           <div className='w-full'>
          <StatsManagement />
          </div>
           <div className='w-full'>
          <StatsTeletravail />
          </div>
           <div className='w-full'>
          <StatsPolitique />
          </div>
          </div>






        {/* <div className='pt-2 mb-2'><SynthesisStats /></div>
<LineChart data={chartData} /> */}

      </div>
    </Sidebar2>
  );
};

export default Synthesis;
